import jQuery from "jquery";
import angular from "angular";
import lodash from "lodash";
import "angular-scroll";
import "angular-animate";

import themes from "./themes.json";
import eventContent from "./eventContent.json";
import commonContent from "./commonContent.json";
import translationsEn from "../translations/en.json";
import translationsDe from "../translations/de.json";

window.$ = jQuery;
window.jQuery = jQuery;
window.angular = angular;

require("./vendor/SweetAlert");
require("./vendor/sweetalert.min");
require("./vendor/airlst-sdk-1.1.1");
require("angular-sanitize");
require("angular-translate");
require("./vendor/angular-locale_de-de");

require("./airlst.js");

angular
  .module("app", [
    "oitozero.ngSweetAlert",
    "AirLST-SDK",
    "ngSanitize",
    "ngAnimate",
    "duScroll",
    "pascalprecht.translate",
  ])
  .directive("ngEnter", [
    function () {
      return function (scope, element, attrs) {
        element.bind("keydown keypress", function (event) {
          if (event.which === 13) {
            scope.$apply(function () {
              scope.vm.loadRsvp();
            });

            event.preventDefault();
          }
        });
      };
    },
  ])
  .value("duScrollOffset", 130)
  .config(function ($translateProvider) {
    $translateProvider.useSanitizeValueStrategy("sceParameters");
    $translateProvider.translations("en", translationsEn);
    $translateProvider.translations("de", translationsDe);
    $translateProvider.preferredLanguage("de");
  })

  .controller("ContactFormController", [
    "$scope",
    "$http",
    function ($scope, $http) {
      var vm = this;

      vm.contact_message = "";
      vm.errormsg = "";
      vm.message_sent = false;
      vm.contact_topic = "";
      vm.rsvpInformation = {
        contact: {},
        rsvp: {},
        recommendations: [],
        guests: [],
      };

      vm.guestCheckboxInformation = [];

      vm.setRsvpInformation = function (rsvpInfo) {
        vm.rsvpInformation = rsvpInfo;
      };

      vm.contactFormSubmit = function (form) {
        var complete = true;

        if (vm.contact_message == "") {
          complete = false;

          if (vm.language == "de") {
            vm.errormsg = "Bitte vor dem Senden eine Nachricht eingeben.";
          } else {
            vm.errormsg = "Please enter a message before sending.";
          }
        }

        if (complete) {
          $http({
            url: "https://wrapper.airlst.com/mail-symposium",
            method: "POST",
            data: {
              message: vm.contact_message,
              first_name: vm.rsvpInformation.contact.first_name,
              last_name: vm.rsvpInformation.contact.last_name,
              email: vm.rsvpInformation.contact.email,
              contact_topic: vm.contact_topic,
              firma: vm.rsvpInformation.contact.company_name,
              phone: vm.rsvpInformation.contact.phone,
            },
          }).then(
            function (response) {
              // success
              vm.message_sent = true;
            },
            function (response) {
              // optional
              // failed

              if (vm.language == "de") {
                alert(
                  "Ihre Nachricht konnte nicht gesendet werden. Bitte wenden Sie sich per E-Mail an Ihren Ansprechpartner."
                );
              } else {
                alert(
                  "Your message could not be sent. Please contact your contact person by Email."
                );
              }
            }
          );
        }
      };
    },
  ])

  .controller("AirLSTCtrl", [
    "$scope",
    "$http",
    "$location",
    "SweetAlert",
    "$document",
    "AirLSTSdkService",
    "$sce",
    "$q",
    "$window",
    "$timeout",
    "$translate",
    function (
      $scope,
      $http,
      $location,
      SweetAlert,
      $document,
      AirLSTSdkService,
      $sce,
      $q,
      $window,
      $timeout,
      $translate
    ) {
      var vm = this;

      vm.hasError = false;
      vm.loading = true;
      vm.submitting = false;
      vm.currentView = "code";
      //   vm.currentView = "content";
      //   vm.hasCode = false;
      //   vm.hasCode = true;
      vm.mobileNavIsOpen = false;
      vm.tab = "";
      vm.language = "de";

      vm.contactFormModel = {
        topic: "",
        message: "",
      };

      vm.countGala = function() {
        vm.galaLimitReached = false;
        vm.counter = 0;
        angular.forEach(vm.rsvpInformation.guests, function(value, key) {
          if( value.rsvp.custom_4 === 'ja') {
            vm.counter++
          }
        });
        if(vm.rsvpInformation.contact.custom_2 <= vm.counter || vm.rsvpInformation.contact.custom_2 === '') {
          vm.galaLimitReached = true;
        }
      }

      /**
       *
       * Tabs for navigation
       */

      // multi step form for REGISTRATION

      vm.getTranslatedEvent = function (word) {
        return lodash.get(eventContent, vm.language + "." + word, "");
      };

      vm.getTranslatedCommonContent = function (word) {
        return lodash.get(commonContent, vm.language + "." + word, "");
      };

      vm.openDataPrivacy = function () {
        vm.overlay = true;
        vm.dataPrivacy = true;
      };

      vm.openImprint = function () {
        vm.overlay = true;
        vm.imprint = true;
      };

      vm.closeOverlay = function () {
        vm.overlay = false;
        vm.dataPrivacy = false;
        vm.imprint = false;
      };

      vm.setTab = function (tabId) {
        vm.tab = tabId;
        vm.currentView = "content";
        // check
        $document.scrollToElementAnimated(anmeldung);
        $scope.$applyAsync();
      };
      vm.isSet = function (tabId) {
        return vm.tab === tabId;
      };

      vm.setTabSub = function (tabId) {
        // $document.scrollToElementAnimated(anmeldung);
        vm.tabSub = tabId;
      };
      vm.isSetSub = function (tabId) {
        return vm.tabSub === tabId;
      };

      vm.setTabSub2 = function (tabId) {
        // $document.scrollToElementAnimated(anmeldung);
        vm.tabSub2 = tabId;
      };
      vm.isSetSub2 = function (tabId) {
        return vm.tabSub2 === tabId;
      };

      /**
       * Initializes the application and loads all necessary data from the server
       *
       * @private
       */
      vm._init = function () {
        AirLSTSdkService.companyUid = "GTAYAF3";
        AirLSTSdkService.guestlistUid = "SCNTT3WP6S";
        AirLSTSdkService.apiUrl = "https://v1.api.airlst.com/lp";

        vm._resetRsvpInformation();
        vm._resetRsvpCode();
        vm._resetAnswerType();
        vm._initCountDown();
        vm._prepareDeposits();
        vm._initWatchers();
        return vm._retrieveEventInformation();
      };

      vm.changeLanguage = function (key) {
        var lang_key = key.toLowerCase();
        $translate.use(lang_key);
        vm.language = lang_key;
        vm._prepareDeposits();
      };

      // Public functions

      /**
       * Queries the airlst server for a rsvp
       *
       * vm.rsvpCode has to be set to a valid rsvp code for this call to succeed. If the API responds with an rsvp,
       * the 'content' view will be loaded next.
       *
       * If an error occurs during the API call, rsvp information is reset an error message will be displayed.
       *
       * @returns {PromiseLike<T | never> | Promise<T | never> | *}
       */
      vm.loadRsvp = function () {
        vm.loading = true;
        return AirLSTSdkService.retrieveRsvpInformation(vm.rsvpCode).then(
          function (rsvpInfo) {
            vm.rsvpInformation = vm._prepareRsvpInformationFromApi(rsvpInfo);
            vm.recommendationInformation =
              vm._prepareRsvpInformationFromApi(rsvpInfo);

            vm.hasCode = true;
            vm.loading = false;

            switch (vm._checkRsvpActionOnUrl()) {
              case "cancel":
                vm.cancelRsvp();
                break;
              default:
                vm.goToView("content");
                // vm.setTab("welcome");
                vm.setTab("registration");
                break;
            }
          },
          function (error) {
            vm.loading = false;
            vm.hasError = true;
            vm._resetRsvpInformation();

            switch (error.identifier) {
              case "rsvp_not_found":
                vm._translatedAlert("error", "RSVP_NOT_FOUND");
                break;
              case "re_login_not_allowed":
                vm._translatedAlert("error", "RSVP_NOT_FOUND");
                break;
              default:
                vm._translatedAlert("error", "TRANSMISSION_ERROR");
                break;
            }
          }
        );
      };

      /**
       * Cancels a rsvp
       *
       * vm.rsvpCode has to be set to a valid rsvp code for this call to succeed. Also the supplied form has to
       * pass validation.
       *
       * After successful cancellation, the form is reset, a success message is displayed and the 'code' view will
       * be shown next.
       *
       * If an error occurs during the API call an error message is displayed.
       *
       * @param form
       */
      vm.cancelRsvp = function (form) {
        if (form && !vm._validateRegistrationForm(form)) {
          return;
        }

        vm.submitting = true;
        AirLSTSdkService.updateRsvpByCodeAndStatus(
          vm.rsvpCode,
          "cancelled",
          vm._prepareRsvpInformationForApi(),
          "cancelled"
        ).then(
          function (rsvpInfo) {
            // vm._init();
            vm.submitting = false;

            vm._translatedAlert("success", "CANCEL_SUCCESS");
            vm.goToView("content");
            vm.rsvpInformation.rsvp.status = "cancelled";
            vm.setTab("done");
          },
          function (error) {
            vm.hasError = true;
            vm.submitting = false;

            switch (error.identifier) {
              case "validation":
                SweetAlert.swal(
                  "Daten fehlerhaft",
                  "Bitte überprüfen Sie Ihre Daten.",
                  "error"
                );
                break;
              case "rsvp_not_found":
              default:
                vm._translatedAlert("error", "TRANSMISSION_ERROR");
                break;
            }
          }
        );
      };

      /**
       * Confirms a rsvp
       *
       * vm.rsvpCode has to be set to a valid rsvp code for this call to succeed. Also the supplied form has to
       * pass validation.
       *
       * After successful confirmation, the form is reset, a success message is displayed and the 'code' view will
       * be shown next.
       *
       * If an error occurs during the API call an error message is displayed.
       *
       * @param form
       */

      vm.confirmRsvp = function (form) {
        if (!vm._validateRegistrationForm(form)) {
          return;
        }
        vm.submitting = true;
        var status = "confirmed";

        if (
          vm.eventInformation.pax_limit_information.code.waitlist &&
          vm.eventInformation.pax_limit_information.code.free_slots == 0
        ) {
          status = "waitlisted";
        }
        AirLSTSdkService.updateRsvpByCodeAndStatus(
          vm.rsvpCode,
          status,
          vm._prepareRsvpInformationForApi(),
          status
        ).then(
          function (rsvpInfo) {
            // vm._init();
            vm.submitting = false;
            vm.rsvpInformation.rsvp.status = "confirmed";
            vm._translatedAlert("success", "REGISTRATION_SUCCESS");
            vm.goToView("content");
            vm.setTab("done");
          },
          function (error) {
            vm.hasError = true;
            vm.submitting = false;

            switch (error.identifier) {
              case "validation":
                vm._translatedAlert("error", "DEFAULT_ERROR");
                break;
              case "rsvp_not_found":
              default:
                vm._translatedAlert("error", "TRANSMISSION_ERROR");
                break;
            }
          }
        );
      };

      /**
       * Confirms a rsvp
       *
       * vm.rsvpCode has to be set to a valid rsvp code for this call to succeed. Also the supplied form has to
       * pass validation.
       *
       * After successful confirmation, the form is reset, a success message is displayed and the 'code' view will
       * be shown next.
       *
       * If an error occurs during the API call an error message is displayed.
       *
       * @param form
       */

      vm.submitRecommendations = function (form) {
        if (!vm._validateRegistrationForm(form)) {
          return;
        }
        var data = vm._prepareRsvpInformationForApi();
        data.email_blocked = true;

        AirLSTSdkService.updateRsvpByCode(vm.rsvpCode, data).then(
          function (rsvpInfo) {
            // vm._init();
            vm.submitting = false;
            vm.rsvpInformation.rsvp.status = "confirmed";
            vm._translatedAlert(
              "success",
              "REGISTRATION_RECOMMENDTIONS_SUCCESS"
            );

            // vm.loadRsvp().then(function () {
            vm.setTab("trading");
            // });
          },
          function (error) {
            vm.hasError = true;
            vm.submitting = false;

            switch (error.identifier) {
              case "validation":
                vm._translatedAlert("error", "DEFAULT_ERROR");
                break;
              case "rsvp_not_found":
              default:
                vm._translatedAlert("error", "TRANSMISSION_ERROR");
                break;
            }
          }
        );
      };

      /**
       * Confirms a rsvp
       *
       * vm.rsvpCode has to be set to a valid rsvp code for this call to succeed. Also the supplied form has to
       * pass validation.
       *
       * After successful confirmation, the form is reset, a success message is displayed and the 'code' view will
       * be shown next.
       *
       * If an error occurs during the API call an error message is displayed.
       *
       * @param form
       */

      vm.createRsvp = function (form) {
        if (!vm._validateRegistrationForm(form)) {
          return;
        }
        vm.submitting = true;

        if (!vm.rsvpInformation.hasOwnProperty("rsvp")) {
          vm.rsvpInformation.rsvp = {};
        }

        vm.rsvpInformation.rsvp.status = "requested";

        AirLSTSdkService.submitOpenRequest(
          vm._prepareRsvpInformationForApi()
        ).then(
          function (rsvpInfo) {
            vm._init();
            vm.submitting = false;
            vm.goToView("content");
            vm.setTab("home");
          },
          function (error) {
            vm.hasError = true;
            vm.submitting = false;
          }
        );
      };
      /**
       * Navigates to a different page
       *
       * @param viewToGoTo
       */
      vm.goToView = function (viewToGoTo) {
        vm.currentView = viewToGoTo;
        $scope.$applyAsync();
      };

      /**
       * Sets answer type
       * Is used as a helper for the landingpage to display different views
       *
       * Possible Values: undifined, cancel, confirmed
       * @param newAnswerType
       */
      vm.setAnswerType = function (newAnswerType) {
        vm.answerType = newAnswerType;
      };

      // Private internal functions

      /**
       * Checks if the current URL contains the rsvp_code GET parameter and tries to load the rsvp by the
       * supplied parameter
       *
       * @private
       */
      vm._checkRsvpCodeOnUrl = function () {
        var match = $location.absUrl().match(/rsvp_code=([A-Za-z0-9]+)/);

        if (match && match.length >= 2 && match[1]) {
          vm.rsvpCode = match[1];
          vm.loadRsvp();
        } else {
          vm.loading = false;
        }
      };

      /** POinti doc */
      vm._checkRsvpActionOnUrl = function () {
        var match = $location.absUrl().match(/action=([A-Za-z0-9]+)/);

        if (match && match.length >= 2 && match[1]) {
          return match[1];
        } else {
          return false;
        }
      };

      /** POinti doc */
      // vm._checkLanguageOnUrl = function () {
      //     var match = $location.absUrl().match(/language=([A-Za-z0-9]+)/);
      //
      //     if (match && match.length >= 2 && match[1]) {
      //         vm.changeLanguage(match[1]);
      //         return;
      //
      //     } else {
      //         return false;
      //     }
      // };

      /**
       *
       * Validates the supplied form
       *
       * @param form
       * @returns {boolean}
       * @private
       */
      vm._validateRegistrationForm = function (form) {
        vm.formValidationReg = true;

        if (!form.$valid) {
          vm._translatedAlert("warning", "MISSING_DATA");
          return false;
        } else {
          vm.formValidationReg = false;
          return true;
        }
      };

      // multi step form for REGISTRATION
      vm.formValidationReg = false;

      /**
       * Adds an empty contact and rsvp object in the guests array
       *
       * This is the place for pre-filling fields in the rsvp, contact, or guest objects *before* the user edits
       * them
       */

      vm.addGuest = function () {
        vm.rsvpInformation.guests.push({
          contact: {
            company_name: vm.rsvpInformation.contact.company_name,
            custom_1: vm.rsvpInformation.contact.custom_1,
            custom_4: vm.rsvpInformation.contact.custom_4,
          },
          rsvp: {
            status: "confirmed",
            custom_1: false,
            custom_2: false,
            custom_3: false,
            custom_11: false,
            custom_12: false,
            custom_13: false,
            custom_14: false,
            custom_15: false,
          },
        });
      };

      /**
       * Deletes the given guest object from the guest array
       *
       * @param guest
       */
      vm.removeGuest = function (guest) {
        var index = vm.rsvpInformation.guests.indexOf(guest);

        if (index > -1) {
          vm.rsvpInformation.guests.splice(index, 1);
        }

        if (vm.rsvpInformation.guests.length === 0) {
          vm.companion = false;
        }
      };

      /**
       * Adds an empty contact and rsvp object in the Recommendation array
       */
      vm.addRecommendation = function () {
        vm.recommendationInformation.recommendations.push({
          contact: {
            custom_1: "Handel",
          },
          rsvp: {},
        });
      };

      /**
       * Deletes the given guest object from the Recommendation array
       *
       * @param guest
       */
      vm.removeRecommendation = function (recommendation) {
        var index =
          vm.recommendationInformation.recommendations.indexOf(recommendation);

        vm.recommendationInformation.recommendations.splice(index, 1);
      };

      vm.submitContactForm = function (form) {
        if (form.$valid) {
          $http
            .post("https://wrapper.airlst.com/mail-symposium", {
              message: vm.contactFormModel.message,
              first_name: vm.rsvpInformation.contact.first_name,
              last_name: vm.rsvpInformation.contact.last_name,
              email: vm.rsvpInformation.contact.email,
              contact_topic: vm.contactFormModel.topic,
              firma: vm.rsvpInformation.contact.company_name,
              phone: vm.rsvpInformation.contact.phone,
            })
            .then(
              function (response) {
                vm._translatedAlert("success", "CONTACT_FORM_SUCCESS");
                vm.contactFormModel = {
                  topic: "",
                  message: "",
                };
                form.$setPristine();
              },
              function (response) {
                // optional
                vm._translatedAlert("error", "CONTACT_FORM_ERROR");
              }
            );
        }
      };

      /**
       * Populates vm.eventInformation with guestlist data from the server
       *
       * @private
       */

      vm._translatedAlert = function (type, messageNamespace) {
        $q.all([
          $translate("ALERTS." + messageNamespace + "_TITLE"),
          $translate("ALERTS." + messageNamespace + "_CONTENT"),
        ]).then(function (results) {
          SweetAlert.swal(results[0], results[1], type);
        });
      };

      vm._retrieveEventInformation = function () {
        vm.eventInformation = null;

        return AirLSTSdkService.retrieveGuestlistInformation().then(
          function (eventInformation) {
            vm.eventInformation = eventInformation;
            $scope.$applyAsync();
            vm._prepareCheckInAndOutDate();
          },
          function () {
            vm.hasError = true;

            SweetAlert.swal(
              "Fehler",
              "Die Informationen zum gewünschten Event konnten nicht geladen werden",
              "error"
            );
          }
        );
      };

      vm._prepareCheckInAndOutDate = function () {
        vm.availableCheckInDates = [];
        vm.availableCheckOutDates = [];
      };

      /**
       * Returns rsvp data ready to be submitted
       *
       * @returns {*|null}
       * @private
       */

      vm._prepareRsvpInformationForApi = function (useRecommendationData) {
        var data = angular.copy(
          useRecommendationData
            ? vm.recommendationInformation
            : vm.rsvpInformation
        );

        // if (vm.language == "en") {
        //   data.contact.language = "EN";
        // } else {
        //   data.contact.language = "DE";
        // }

        // if (data.contact.custom_1 == "Handel") {
        //   data.email_blocked = true;
        // }

        return data;
      };

      /**
       * Transforms rsvp data supplied by the API
       *
       * @param inData
       * @returns {*}
       * @private
       */
      vm._prepareRsvpInformationFromApi = function (inData) {
        // vm.companion = inData.guests.length > 0 ? vm.companion = true : vm.companion = false;
        // vm.companion = inData.guests.length > 0;

        // if (inData.contact.language == "EN") {
        //   vm.changeLanguage("en");
        // }

        return inData;
      };

      vm._resetRsvpInformation = function () {
        vm.rsvpInformation = null;
        vm.recommendationInformation = null;
      };

      vm._resetRsvpCode = function () {
        vm.rsvpCode = "";
      };

      vm._resetAnswerType = function () {
        vm.answerType = '';
      };

      vm._initCountDown = function () {
        vm.countdownTime =
          (new Date(2020, 0, 31, 14, 0, 0) - new Date()) / 1000;
      };

      vm._prepareDeposits = function () {
        vm.addedvalue = lodash.map(
          lodash.get(themes, vm.language + ".added_value", []),
          function (addedvalue) {
            return {
              img: addedvalue.img,
              title: $sce.trustAsHtml(addedvalue.title),
              description: $sce.trustAsHtml(addedvalue.description),
            };
          }
        );
      };

      vm._initWatchers = function () {
        $scope.$watch(
          function () {
            return vm.rsvpInformation;
          },
          function () {
            const newGuestCheckboxInformation = [],
              checkboxFields = [
                "custom_1",
                "custom_2",
                "custom_3",
                "custom_11",
                "custom_12",
                "custom_13",
                "custom_14",
                "custom_15",
              ];

            if (vm.rsvpInformation) {
              lodash.map(
                vm.rsvpInformation.guests,
                function (guestInformation, index) {
                  newGuestCheckboxInformation[index] = {
                    required: true,
                    models: {},
                  };

                  _.map(checkboxFields, function (field) {
                    const curVal = !!guestInformation.rsvp[field];
                    newGuestCheckboxInformation[index].models[field] = curVal;

                    if (curVal) {
                      newGuestCheckboxInformation[index].required = false;
                    }
                  });
                }
              );
            }

            vm.guestCheckboxInformation = newGuestCheckboxInformation;
          },
          true
        );
      };

      vm.guestCheckboxChanged = function (index, field) {
        if (!vm.rsvpInformation || !vm.guestCheckboxInformation[index]) {
          return;
        }

        vm.rsvpInformation.guests[index].rsvp[field] =
          vm.guestCheckboxInformation[index].models[field];
      };

      vm._init().then(function () {
        vm._checkRsvpCodeOnUrl();
      });
    },
  ]);
